import React from 'react'
import { Link, graphql } from 'gatsby'
import Seo from '@components/seo'
import { GatsbyImage } from "gatsby-plugin-image"
import Main from '@components/main'
import '@styles/object/project/info.scss'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { INLINES } from "@contentful/rich-text-types";

export const query = graphql`
  query ($contentfulId: String!) {
    contentfulInfo (contentful_id: {eq: $contentfulId}) {
      title
      date (formatString: "YYYY年M月D日")
      text {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
          }
        }
      }
    }
  }
`

const options = {
  renderNode: {
    "embedded-asset-block": node => {
      const gatsbyImageData = node.data.target.gatsbyImageData
      if (!gatsbyImageData) {
        return null
      }
      return <GatsbyImage image={gatsbyImageData} />
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const isLinkToMySite = node.data.uri.startsWith(process.env.GATSBY_SITE_ORIGIN)
      if (isLinkToMySite) {
        // 内部リンク
        return <Link to={node.data.uri}>{children}</Link>
      }
      // 外部リンク
      return <a href={node.data.uri} target="_blank" rel="noopener noreferrer">{children}</a>
    },
  },
}

const Info = (props) => {
  return (
    <Main>
      <Seo title="お知らせ" description={props.data.contentfulInfo.title} ogType="article" />
      <article className="info">
        <div className="info__container-border">
          <div className="info__container">
            <h2 className="info__title">{props.data.contentfulInfo.title}</h2>
            <div className="info__publish_date">
              <time>{props.data.contentfulInfo.date}</time>
            </div>
            <div className="info__text">{renderRichText(props.data.contentfulInfo.text, options)}</div>
          </div>
        </div>
      </article>
    </Main>
  );
};

export default Info